<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="60%" :before-close="handleClose" center>
    <div>

      <el-row :gutter="24">
        <el-col :span="12">
          <el-button type="primary" @click="checkExistButton('dynamicValidateForm')">检测数据</el-button>
        </el-col>
        <el-col :span="12">
          <el-upload ref="bookUploader" class="upload-demo" style="margin: auto;" drag action="" :on-error="handleUploadSuccess">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-col></el-row>

    </div>

    <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="100px">
      <el-row :gutter="24" v-for="domain in dynamicValidateForm.xunLikeBooksImportList" :key="domain.id">
        <el-col :span="5">
          <el-form-item label="书名" width="120px" :prop="domain.name" :rules="{
            required: true,
            message: '书名不能为空',
            trigger: 'blur',
          }">
            <el-input v-model="domain.name" />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="作者" width="120px" prop="author">
            <el-input v-model="domain.author" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="备注" width="120px" prop="remark">
            <el-input v-model="domain.remark" />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <span style="color: red;">
            <div v-html="domain.infoStatus"></div>
          </span>

          <span style="color: orange;">
          <div v-html="domain.warningData"></div>
          </span>
        </el-col>
        <el-col :span="2">
          <el-button type="danger" icon="el-icon-delete" @click.prevent="removeDomain(domain)" circle></el-button>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="quickRecordButton()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { checkExistApi, quickRecordApi, batchImportApi } from "@/api/life/book/xunLikeBooks";
export default {
  name: "quickFood",
  data() {
    return {
      dialogVisible: false,
      dynamicValidateForm: {
        id: 1,
        xunLikeBooksImportList: [
          {
            name: null,
            remark: null,
          },
        ],
        fileData: {}
      },
    };
  },
  methods: {
    //打开弹窗
    show(row, type) {
      this.dialogVisible = true;
    },
    cancel() {
      this.$refs.bookUploader.clearFiles()
      var dynamicValidateFormInit = {
        id: 1,
        xunLikeBooksImportList: [
          {
            name: null,
            remark: null,
          },
        ],
      };
      this.dynamicValidateForm = dynamicValidateFormInit;
      this.dialogVisible = false;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.cancel();
        })
        .catch((_) => { });
    },
    addQuickButton() {
      var data = {
        name: null,
      };
      this.dynamicValidateForm.xunLikeBooksImportList.push(data);
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.xunLikeBooksImportList.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.xunLikeBooksImportList.splice(index, 1);
      }
    },
    checkExistButton(formName) {
      const hasNullName = this.dynamicValidateForm.xunLikeBooksImportList.some(
        (item) => item.name === null
      );
      console.log("=", hasNullName);
      if (hasNullName) {
        return this.$message.error("请将书名填完整");
      }
      checkExistApi(this.dynamicValidateForm).then((res) => {
        this.dynamicValidateForm.xunLikeBooksImportList = res.result;
      });
    },
    quickRecordButton() {
      quickRecordApi(this.dynamicValidateForm).then((res) => {
        this.cancel();
        this.$parent.xunLikeBooksButtonList();
      });
    },
    // 函数应该是用错了 等确定好:action的用处在调整
    handleUploadSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      batchImportApi(file.raw).then(res => {
        console.log(res.result)
        this.dynamicValidateForm.xunLikeBooksImportList = res.result;
      })
    }
  },
};
</script>
<style lang="less" scoped>
.el-upload {
  display: block !important;
  /* 或者指定您希望的其他display值 */
}
</style> 


